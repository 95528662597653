


























import { Component, Vue } from "vue-property-decorator";
import { IQuestionnaireData } from "@/api/types";
import { getQuestionnaire } from "@/api/questionnaire";
import { Message } from "element-ui";
import QuestionnaireThumbnail from "@/components/QuestionnaireThumbnail.vue";

@Component({
  name: "Accueil",
  components: {
    QuestionnaireThumbnail,
  },
})
export default class Accueil extends Vue {
  //#region Data
  private questionnaires: IQuestionnaireData[] = [];
  private loading = false;
  //#endregion

  //#region Hooks
  created() {
    this.getQuestionnaires();
  }
  //#enregion

  //#region Methods
  // Chargement de la liste des questionnaires
  private async getQuestionnaires() {
    this.loading = true;
    try {
      const { data } = await getQuestionnaire(null);
      this.questionnaires = data.items;
      //console.log(data);
      //console.log(this.questionnaires);
    } catch (error) {
      Message({
        message: "Erreur lors de la récupération des questionnaires.",
        type: "error",
        duration: 5 * 1000,
      });
    } finally {
      this.loading = false;
    }
  }
  //#endregion
}
