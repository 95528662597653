






































import { Component, Vue, Prop } from "vue-property-decorator";
import { IQuestionnaireData } from "@/api/types";

@Component({
  name: "QuestionnaireThumbnail",
  filters: {
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toLocaleDateString();
    },
  },
})
export default class QuestionnaireThumbnail extends Vue {
  //#region Props
  @Prop() questionnaire: IQuestionnaireData | undefined;
  //#endregion

  //#region Data
  private loading = false;
  //#endregion

  //#region Computed
  // Statut affiché sur le bouton
  get statusLabel() {
    let s = "";
    switch (this.questionnaire?.questStatus) {
      case 0:
        s = "Compléter";
        break;
      case 1:
        s = "Editer";
        break;
      default:
        s = "Commencer";
        break;
    }
    return s;
  }

  // Image affichée dépendant du statut
  get ImageStatusQuestionnaire() {
    let i = "";
    const images = require.context("@/assets/questionnaire/");
    if (this.questionnaire?.archive) {
      i = "list-lock_64.png";
    } else {
      switch (this.questionnaire?.questStatus) {
        case 0:
          i = "list-edit_64.png";
          break;
        case 1:
          i = "list-ok_64.png";
          break;
        default:
          i = "list_64.png";
          break;
      }
    }
    return images("./" + i);
  }
  //#endregion

  //#region Methods
  private edit() {
    this.$router.push({
      path: `/questionnaire/` + this.questionnaire?.appid.toLowerCase() + "/" + this.questionnaire?.idQuestionnaire,
    });
  }
  //#endregion
}
